<template>
  <div v-if="topWeekProducts.length">
    <ProductSlider
      :productList="topWeekProducts"
      :productToShow="4"
      :infinite="true"
      :arrows="true"
      :dots="true"
      :slidesToScroll="3"
      :sliderResponsive="[
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
          },
        },
      ]"
    />
  </div>
</template>

<script>
import ProductSlider from "@/base/core/components/products/product-detail/ProductSlider";
export default {
  name: "TopProductsCarousel",
  components: {
    ProductSlider,
  },
  computed: {
    topWeekProducts() {
      return this.$store.getters["home/getTopWeekProducts"];
    },
  },
};
</script>