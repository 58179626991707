<template>
  <div>
    <section class="after-payment-page">
      <b-container>
        <b-row>
          <b-col lg="8">
            <div class="content">
              <div class="top">
                <h1>{{ $t("payment_success") }}</h1>
                <div class="transaction-info" v-if="orderId">
                  Je bestelnummer is {{ orderId }}
                </div>
              </div>
              <div class="info-group">
                <v-runtime-template
                  v-if="getCmsData.content"
                  :template="`<div>${getCmsData.content}</div>`"
                ></v-runtime-template>

                <b-link to="/" class="btn btn-primary">
                  Terug naar homepagina
                </b-link>
              </div>
            </div>
          </b-col>
          <b-col lg="4">
            <cmsBlockSimple
              v-if="typeof paymentSuccessImgCms == 'object'"
              :identifier="paymentSuccessImgCms.identifier"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="top-week">
        <b-container>
          <h2 class="section-title">Accessoires vergeten? Geen probleem!</h2>
          <ClientOnly>
            <TopProductsCarousel />
          </ClientOnly>
        </b-container>
      </div>
    </section>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
import TopProductsCarousel from "@/base/core/components/home/TopProductsCarousel";

import { getOrderInfoFromHash } from "@storefront/core/data-resolver/cart";
import config from "@storefront/core/lib/utils/config";
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "PaymentSuccess",
  components: {
    cmsBlockSimple,
    TopProductsCarousel,
    ClientOnly,
    VRuntimeTemplate,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.$t("payment_success") },
        { name: "keywords", content: this.$t("payment_success") },
        { name: "description", content: this.$t("payment_success") },
      ],
      title: this.$t("payment_success"),
    };
  },
  data() {
    return {
      orderId: null,
    };
  },
  computed: {
    getCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "checkout_payment_succes_text"
      );
    },
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
    paymentSuccessImgCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "thank_you_page_image"
      );
    },
  },
  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    const hashId = localStorage.getItem("orderHash");

    if (hashId) {
      const order = await getOrderInfoFromHash(hashId);
      if (order && order.number && order.number.length > 0) {
        this.orderId = order.number;
        if (this.$gtm.enabled() == true) {
          const items = [];
          const prods = [];
          order.items.forEach((element) => {
            let item = {};
            let prod = {};
            item.id = element.product_sku;
            item.name = element.product_name;
            item.price = element.product_sale_price;
            item.quantity = element.quantity_ordered;
            prod.item_id = element.product_sku;
            prod.item_name = element.product_name;
            prod.price = element.product_sale_price;
            prod.quantity = element.quantity_ordered;
            items.push(item);
            prods.push(prod);
          });

          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                currencyCode: "EUR",
                purchase: {
                  actionField: {
                    id: order.number,
                    affiliation: config.app_name,
                    revenue: order.total.subtotal.value,
                    tax: order.total.total_tax.value,
                    shipping: order.total.total_shipping.value,
                  },
                  products: items,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                add: {
                  transaction_id: order.number,
                  affiliation: config.app_name,
                  value: order.total.subtotal.value,
                  tax: order.total.total_tax.value,
                  shipping: order.total.total_shipping.value,
                  items: prods,
                },
              },
            });
          }
        }
      }
    }
    this.$store.commit("product1/setServicePostcode", "");
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";
@import "@/base/core/views/payment/payment.scss";

.info-text label {
  font-size: 18px;
  font-weight: 500;
  color: green;
}
.after-payment-page {
  padding-top: 20px;
  h1 {
    font-size: 22px;
    margin-bottom: 0;
  }
  .top {
    border-bottom: 1px solid $borderLightColor;
    margin-bottom: 10px;
  }
  .info-group {
    font-size: 14px;
    max-width: 550px;
    line-height: 23px;
    width: 100%;
    .btn {
      margin-bottom: 30px;
      width: 100%;
    }
    .info-text {
      label {
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
</style>

