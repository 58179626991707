var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.topWeekProducts.length)?_c('div',[_c('ProductSlider',{attrs:{"productList":_vm.topWeekProducts,"productToShow":4,"infinite":true,"arrows":true,"dots":true,"slidesToScroll":3,"sliderResponsive":[
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      } ]}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }