<template>
  <v-runtime-template
    :class="['cms-block-content', { container: sync }]"
    v-if="getCmsData"
    :template="`<div>` + getCmsData.content + `</div>`"
  ></v-runtime-template>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "CmsBlockSimple",
  components: { VRuntimeTemplate },
  props: {
    id: {
      type: Number,
      default: null,
      required: false,
    },
    identifier: {
      type: String,
      default: null,
      required: false,
    },
    sync: {
      type: Boolean,
      default: false,
      required: false,
    },
    showtitle: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  serverPrefetch() {
    return this.fetchCmsBlock();
  },
  created() {
    if (!isServer) {
      this.fetchCmsBlock();
    }
  },
  methods: {
    fetchCmsBlock() {
      let queryKey = "";
      let queryValue = "";
      if (this.id) {
        queryKey = "id";
        queryValue = this.id;
      } else if (this.identifier) {
        queryKey = "identifier";
        queryValue = this.identifier;
      }
      if (queryKey && queryValue) {
        return this.$store.dispatch("cmsBlock/single", {
          key: queryKey,
          value: queryValue,
        });
      }
    },
  },
  computed: {
    getCmsData() {
      if (this.id) {
        return this.$store.getters[`cmsBlock/getCmsBlockById`](this.id);
      } else if (this.identifier) {
        return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          this.identifier
        );
      }
      return null;
    },
  },
};
</script>
<style lang="scss">
@import "@/base/scss/variables.scss";
.cms-block-content {
  width: 100%;
  .informative-text {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    .check-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid $borderDarkColor;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      position: relative;
      &:before {
        content: "";
        transform: rotate(45deg);
        height: 10px;
        width: 6px;
        border-bottom: 2px solid $primaryColor;
        border-right: 2px solid $primaryColor;
        position: absolute;
        top: 2px;
        left: 5px;
      }
    }
    .content {
      width: calc(100% - 26px);
    }
  }
}
</style>
